.testimonial {
  margin: 20px 110px 20px 110px;
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.16);
  background-color: white;
  .testimonial_row {
    max-width: 80%;
    margin: auto;
    padding: 3em 2em;
  }
  .testimonial_details {
    font-size: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
  @media (max-width: 576px) {
    .XmyQV {
      font-size: 20px;
    }
  }
}

@mixin content_title_common {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: center;
  color: #242b30;
  padding-top: 12px;
}
@mixin header_title_common {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 3px;
  text-align: center;
  color: #242b30;
  padding-top: 17px;
}

@mixin bottom_tip {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -36px;
  border-width: 40px;
  border-style: solid;
}
.card_wrapper {
  max-width: 89vw;
  margin: 0 auto;
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.16);
  background-color: white;

  .card_content_wrapper {
    cursor: pointer;
    padding: 41px 43px 79px 43px;

    position: relative;
    .header_title {
      @include header_title_common;
    }
    .content_title {
      @include content_title_common;
    }

    &.active {
      .content_title {
        @include content_title_common;
        color: white;
      }
      .header_title {
        @include header_title_common;
        color: white;
      }
      &.bg_star {
        background-color: #44b7b4 !important;
      }
      &.bg_compass {
        background-color: #a42e5d !important;
      }
      &.bg_office {
        background-color: #242b30 !important;
      }
      &.bg_floorplan {
        background-color: #1292c4 !important;
      }
      .svg_hover{
        fill: white;
      }
      //bottom tip
      .bottom_content_star::after {
        @include bottom_tip;
        border-color: #44b7b4 transparent transparent transparent;
      }

      .bottom_content_compass::after {
        @include bottom_tip;
        border-color: #a42e5d transparent transparent transparent;
      }

      .bottom_content_office::after {
        @include bottom_tip;
        border-color: #242b30 transparent transparent transparent;
      }
      .bottom_content_floorplan::after {
        @include bottom_tip;
        border-color: #1292c4 transparent transparent transparent;
      }
    }
  }
}


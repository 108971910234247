.container {
   line-height: 1.5;
   letter-spacing: 1px;
  .Terms {
    font-family:'Proxima Nova W01 Regular';
    font-size: 41px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 2.16px;
    color: #242b30;
    margin-bottom: 60px;
  }
  .policy{
      margin-bottom: 100px !important;
      font-family: 'Proxima Nova W01 Regular';
  }
  .policy > p {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .policy > h2 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 80px;
    margin-bottom: 30px;
  }
  .policy > li{
     margin-bottom: 50px;
  }
}

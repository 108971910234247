@import "../../../Style/_variable";
.benefit {
  margin: 0;
  padding: 80px;
  @media (max-width: 576px) {
    padding: 40px 20px;
  }
  .benefit_text {
    .heading {
      letter-spacing: 2px;
      line-height: 1.14;
      width: 614px;
      z-index: 1;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .team_list {
      padding: 30px 38px;
      .team_name{
        letter-spacing: 2px;
      }
      @media (max-width: 576px) {
        padding: 30px 0px;
      }
    }
  }
}

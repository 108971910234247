.business_spectrum {
  background-image: url(../../../assets/spectrum1.jpg);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding:118px 70px;
  display: flex;
  align-items: center;
  .business_spectrum_container {
    position: relative;
    background-color: #fff;
    opacity: 0.95;
    .business_spectrum_heading {
      color: #242b30;
      line-height: 1.24;
      letter-spacing: 3px;
      max-width:497px;
    }
    .early_stage_contain{
        letter-spacing: 1px;
        line-height: 1.43;

    }
    .business_spectrum_list {
      .early_stage {
        .early_stage_heading {
          padding-top: 10px;
          color: #242b30;
        }
        .early_stage_contain {
          color: #242b30;
        }
      }
    }
    .heading-title {
      letter-spacing: 3px;
      line-height: 1.42;
    }
  }
}

@media screen and (max-width: 426px) {
  .business_spectrum {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    .business_spectrum_container {
      margin-bottom: 10px;
      
    }
  }
}

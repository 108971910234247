@import "../../Style/_variable";

@mixin nav_link_style {
  .nav-item {
    .nav-link {
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 2px;
      font-family: "Proxima Nova W01 Regular";
      color: $black;
    }
  }
}
.dropdown-item {
  &:active,&.active{
    background-color: #fff !important;
    color:#242b30 !important;
  }
  
  letter-spacing: 2px;
  & > div {
    font-weight: bold;
  }
}
.nav_items {
  font-size: 16px;
  font-weight: 600;
  color: $team_list_name;
}
.nav_img_call{
  height: auto;
  width: auto;
}
.nav_call {
  color: $darkPink !important ;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.08px;
  font-family: "Proxima Nova W01 Regular";
}
.navbar_item_list {

  .fixedflicker{
    border-bottom: 3px solid #fff;
    cursor: default;
  }
  .fixedflicker > a{
    cursor: default;
  }
  .fordefault{
    cursor: default !important;
  }
  
  .workplace_list {
   
    @include nav_link_style;
    .show {
      .nav-link {
        color: #1d7a9d;
        border-bottom: 2px solid #1d7a9d;
      }
    }
  }
  .workplace_list > div {
    margin-left: 0px !important;
  }
  .real_estate {

    @include nav_link_style;
    .show {
      .nav-link {
        color: #a42e5d !important;
        border-bottom: 2px solid #a42e5d;
      }
    }
  }
  .real_estate > div {
    margin-left: 0px !important;
  }
  .furniture_list {

    @include nav_link_style;
    .show {
      .nav-link {
        color: #44b7b4 !important;
        border-bottom: 2px solid #44b7b4;
      }
    }
  }
  .furniture_list > div {
    margin-left: 0px !important;
  }
  .dropdown-menu {
    border: none;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.fixed_header {
  top: 0;
  .navbar {
    padding: 10px 36px;
  }
}
.signInLink{
  color:#242b30;
  font-weight: 600;
  letter-spacing: 2px;
}

@media screen and (max-width: 576px) {
  .nav_img_call{
    height: 20px;
    width: 20px;
  }
}


.dropdown-wrapper {
  position: relative;
  .dropdownArrow {
    position: absolute;
    width: 10px;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
  .classnames{
    font-family: "Proxima Nova W01 Regular";
    letter-spacing: 1.14px;
    padding:1%;
    color: #7e7e7e;
  }
}

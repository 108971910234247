.case_studies {
  max-width: 1100px;
  padding: 0 20px;
  margin: auto;
  .location {
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  .description{
    letter-spacing: 1px;
  }
    .case_studies_card {
      margin-bottom: 40px;
      cursor:pointer;
      .case_studies_card_bg {
        position: relative;
        margin-bottom: 35px;
        .logo_wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #fff;
          padding: 20px;
          overflow: hidden;
          height: 120px;
          width: 260px;
          display: flex;
          align-items: center;
          justify-content: center;
          .img_logo {
            object-fit: contain;
            max-height: 100%;
          }
        }
      }
    
  }
}

.home_carouseel {
  .carousel {
    .carousel-indicators {
      justify-content: flex-end;
      margin-right: 5%;
      li {
        margin: 0 8px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #ffffff;
      }
      li:active {
        background-color: aqua;
      }
    }
    .carousel-inner {
      .one_stop_shop {
        .carousel-caption{top: auto !important; bottom: 0; transform: none !important; padding-bottom: 5rem;}
      }
      .informed_decisions {
        .carousel-caption{top: 0 !important; bottom: auto; transform: none !important; padding-top: 5rem;}
      }
      .beautiful_building{
        .carousel-caption{top: auto !important; bottom: 0 !important; transform: none !important; display: flex;
          justify-content: flex-end;    padding-bottom: 5rem;}
      }
      .work_balance {
        .carousel-caption{ top: 0 !important; bottom: auto !important; transform: none !important;
          display: flex; align-items: center; justify-content: center; height: 100%;}
      }
      // .carousel-caption {
      //   top:50%;
      //   transform:translate(0,-50%);
      //   .caption_text {
      //     font-weight: normal;
      //     line-height: 1.18;
      //     color: #ffffff !important;
      //     letter-spacing: 4.8px;
      //   }
      //   @media (max-width: 768px){
      //     .caption_text {
      //         font-size: 58px;
      //         letter-spacing: 4px;
      //     }
      //   }
      //   @media (max-width: 576px){
      //     .caption_text {
      //         font-size: 24px;
      //         letter-spacing: 4px;
      //     }
      //     }
      //     @media (max-width: 375px){
      //       .caption_text {
      //           font-size: 23px;
      //           letter-spacing: 3px;
      //       }
      //       }
      //       @media (max-width: 320px){
      //         .caption_text {
      //             font-size: 18px;
      //             letter-spacing: 3px;
      //         }
      //         }
      // }
    }
  }
}

.caption_icon {
  bottom: 5%;
  position: fixed;
  left: 50%;
}

.property_type {
  background-color: #f8f8f8;
  padding: 80px;
  .property_heading {
    margin: 0px 48px;
    line-height: 1.24;
    letter-spacing: 3px;
  }
  .heading-title {
    letter-spacing: 3px;
    line-height: 1.24;
  }
  .property_list {
    .office {
      .office_img {
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .property_type {
    padding: 80px 20px;
    .property_heading{
      margin: 0 20px;
    }
    .property_list {
      justify-content: space-between;
      .office {
        padding: 20px 0px;
      }
    }
  }
}

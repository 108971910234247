.interior_design {
  background-size: cover;
  background-repeat: no-repeat;
  height: 17vw;
  background-position: bottom;
  position: relative;
  // margin-top:111px;
  @media (max-width: 576px) {
    height: 30vw;
  }
  .interior_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.5px;
    text-align: center;
    color: #ffffff;
    @media (max-width: 700px) {
      font-size: 28px;
    }
    @media (max-width: 576px) {
      font-size: 20px;
    }
  }
}

.Main_container {
  .prosek_header {
    font-family: "Proxima Nova W01 regular";
    letter-spacing: 1.4px;
  }

  p {
    font-family: "Proxima Nova W01 regular";
    letter-spacing: 1.4px;
    font-size: 22px;
  }
  h3,
  h5 {
    font-family: "Proxima Nova W01 regular";
    letter-spacing: 1.4px;
  }
  .basedCompany {
    font-weight: 700;
    font-style: italic;
    line-height: 38px;
    font-size: 26px;
    margin: 0 0 20px;
  }
  .bottom_para{
      margin-bottom: 80px;
  }
}

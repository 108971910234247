.carousel-item {
  display: flex i !important;
}

.carousel-item.active {
  display: flex !important;
}
.carousel_Img {
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.Text_div {
  margin-right: 8vw;
  width: 50%;
  margin-bottom: 30px;
  .carousel-subheading {
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.43;
    margin-bottom: 19px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
}

.carouselDiv {
  background-color: #f8f8f8;
  .carousel {
    &.slide {
      max-width: 89vw;
      margin: auto;
    }
    .carousel-Wrapper {
      margin: auto;
      max-width: 75vw;
      margin-top: 100px;
      margin-bottom: 100px;
      @media (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 52px;
        margin-bottom: 52px;
      }
    }
  }
  .carousel-control-prev {
    width: 25px;
    .carousel-control-prev-icon {
      background-image: url("../../../assets/icons/left-arrow.svg");
    }
  }
  .carousel-control-next {
    width: 25px;
    .carousel-control-next-icon {
      background-image: url("../../../assets/icons/right-arrow.svg");
    }
  }
}

.text_size {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #242b30;
  
}

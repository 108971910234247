.client_list {
  margin: 60px 15px 60px 57px;
  padding: 0 0px 0 40px;
  .company_logo {
    margin: 0 10px;
    box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.16);
    background-color: white;
    max-width: 20%;
    width: 14%;
    .logo_img{
        width : 175px;
    }
  }
}
// @media screen and (max-width: 1259px) {
//   .client_list{   
//       margin: 60px 0px 22px 0px;
//       padding: 0 0px 0 0px;
//      .company_logo{
//       margin: 0 10px;
//       box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.16);
//       background-color: white; 
//       max-width: 33%;
//       width: 17%;
//       .logo_img{
//           width : 100px !important;
//       }
//      } 

//   }
// }
@media screen and (max-width: 576px) {
    .client_list{   
        margin: 60px 0px 22px 0px;
        padding: 0 0px 0 0px;
       .company_logo{
        margin: 0 10px;
        box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.16);
        background-color: white; 
        max-width: 33%;
        width: 28%;
        .logo_img{
            width : 100px !important;
        }
       } 

    }
}

//Proxima Nova Semibold
@font-face{
    font-family:"Proxima Nova A W05 Semibold";
    src:url("./Fonts-semibold/5596906/e0293024-855f-492a-b955-d9015e262952.woff2") format("woff2"),url("./Fonts-semibold/5596906/0bb5875f-6330-42b7-9cfd-3f8df6e61023.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova W05 Semibold";
    src:url("./Fonts-semibold/5738926/3e324849-d359-4b66-9b56-bad57426a869.woff2") format("woff2"),url("./Fonts-semibold/5738926/584a9e8f-ec09-4b98-bb1a-7cc22b43da7b.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova S W05 Semibold";
    src:url("./Fonts-semibold/5738939/8acae35c-be6a-4034-9813-f482fc1e139b.woff2") format("woff2"),url("./Fonts-semibold/5738939/870b6b97-ffc7-461c-bce2-9c6ae745f3a9.woff") format("woff");
}

//Proxima Nova Regular
@font-face{
    font-family:"Proxima Nova W01 Regular";
    src:url("./Fonts-regular/5596735/b38b8bd9-d96f-4bf9-add1-adbd2b08b802.woff2") format("woff2"),url("./Fonts-regular/5596735/52ed7b2d-8a31-4b18-a1d5-8685608b0889.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova A W01 Regular";
    src:url("./Fonts-regular/5596876/5e3d63a1-7b0f-4db7-a262-ed0f75342767.woff2") format("woff2"),url("./Fonts-regular/5596876/d1d42421-f8dd-45e1-ad20-c13904c4b641.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova S W01 Regular";
    src:url("./Fonts-regular/5738917/fd1e08ad-5cf5-49c5-a442-598d5254ad14.woff2") format("woff2"),url("./Fonts-regular/5738917/d653b9c7-5c57-4b44-9eee-599aeabda4b5.woff") format("woff");
}

//Proxima Nova Bold

@font-face{
    font-family:"Proxima Nova W01 Bold";
    src:url("./Fonts-bold/5596920/cc85d073-4dcf-4ecd-9804-40c34e631e2b.woff2") format("woff2"),url("./Fonts-bold/5596920/332136cf-33d0-43fb-97ee-49c7742489ff.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova A W01 Bold";
    src:url("./Fonts-bold/5596933/4783a663-d67d-46c9-bfca-af4c5d813b35.woff2") format("woff2"),url("./Fonts-bold/5596933/e0ebf212-56ed-44af-b59a-b03942dbbddb.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova S W01 Bold";
    src:url("./Fonts-bold/5596946/041aeec1-6385-406f-8d6a-29d75442c0fa.woff2") format("woff2"),url("./Fonts-bold/5596946/75093fe6-9a38-4263-9cd1-1a39649adbc1.woff") format("woff");
}
// Proxima Nova Extrabold
@font-face{
    font-family:"Proxima Nova W01 Extrabold";
    src:url("./Fonts-extrabold/5596977/c8e0146f-ebf2-4e86-bd3d-047c9a5e5dac.woff2") format("woff2"),url("./Fonts-extrabold/5596977/e7972ec4-f161-406c-be4c-f32e884989e7.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova A W01 Extrabold";
    src:url("./Fonts-extrabold/5596991/2ec6957d-862c-40f5-b0f2-20507bca7b34.woff2") format("woff2"),url("./Fonts-extrabold/5596991/5193b97c-82f9-4857-bbbf-ae4955daade8.woff") format("woff");
}
@font-face{
    font-family:"Proxima Nova S W01 Extrabold";
    src:url("./Fonts-extrabold/5738972/8abf9af5-0991-4f7d-8b1e-97bbc22a1d50.woff2") format("woff2"),url("./Fonts-extrabold/5738972/9f3e0d12-1981-42ce-ad03-cacc3106b5b8.woff") format("woff");
}
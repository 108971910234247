$team_list_name : #242b30 ;
$darkPink : #a42e5d;
$greeny_blue:#44b7b4;
$water_blue : #1292c4;
$white:#fff;
$black:#242b30;
:export {
    greenyBlue: $greeny_blue;
}
:export {
    waterBlue: $water_blue;
}
.container {
  font-family: 'Proxima Nova W01 Regular';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #242b30;
  .privacy {
    font-size: 41px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 2.16px;
    color: #242b30;
    margin-bottom: 60px;
    font-family: 'Proxima Nova W01 Regular';
  }
  .reesaText > p{
      margin-bottom: 30px;
  }
  .reesaText> h2{
      font-size: 24px;
      font-weight: 500;
      margin-top: 50px;
      margin-bottom: 30px;
  }
  .reesaText > li{
      margin-bottom: 10px;
  }
 
}
